import * as React from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useTheme } from "@mui/material";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  children: React.ReactElement;
}

function ElevationScroll(props: Props) {
  const { children } = props;
  const theme = useTheme();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 60,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    sx: {
      backgroundColor: trigger ? theme.palette.primary.main : "transparent",
      transitionProperty: "background-color, box-shadow",
      transitionDuration: "0.2s",
      transitionTimingFunction: "ease-in-out",
    },
  });
}

export default ElevationScroll;
